<template lang="pug">
	section#blocks
		h2 Bem-vindo!
		h3 Como podemos te ajudar?
		ul.optionList
			li(v-for="item, index in contents", :class="{ ativo: active == index }").optionItem
				a(:href="item.content", v-if="item.type == 'external' || item.type == 'internal'", :target="item.type == 'external' ? '_blank' : ''")
					.icon
						img(:src="image(item.icon)")
					p.topTitle {{item.title}}
				.box(@click="toggle(index)", v-else)
					.icon
						img(:src="image(item.icon)")
					p.topTitle {{item.title}}
				p(v-if="item.tooltip" v-html="item.tooltip").modal
		Content(:contents="grandParent", nestingLevel="0", v-if="active != null" :path.sync="getNewPath")
</template>

<script>
import { props, faleConoscoComponents } from '@/mixins/component'

export default {
	name: "section-blocks",
	props: {
		contents: {
			type: [String, Object, Array],
		},
		path: Array
	},
	components: {
		...faleConoscoComponents,
	},
	data() {
		return {
			active: null,
		}
	},
	watch: {
		getContents: function () {
			if (this.path) 
				this.setActive()
		}
	},
	computed: {
		grandParent() {
			if (this.active == null)
				return {}
			return this.contents[this.active]
		},
		getPath() {
			return this.path
		},
		getContents() {
			return this.contents
		},
		getNewPath() {
			return this.path.filter(item => item !== this.contents[this.active].id)
		}
	},
	methods: {
		setActive() {
			if (this.active != null) 
				return
			if(this.contents.length) {
				this.active = this.contents.indexOf(this.contents.find(item => item.id === this.getPath[0]))
				if (this.active < 0) this.active = null; 
			}
		},
		toggle(index) {
			if (index === this.active)
				this.active = null
			else
				this.active = index
		},
		image(icon) {
			return `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${icon?.id}`
		}
	},
}
</script>

<style lang="stylus" scoped src="./Blocks.styl"></style>
